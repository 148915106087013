@keyframes spinLoader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loader {
    &__body {
        width: 100vw;
        height: 100vh;
        background-color: #ffffffd6;
        border-radius: 4px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 55;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }


    &__spinner {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-color: transparent;
        border: 3px solid #23CBA7;
        border-right-color: transparent;
        animation: spinLoader 2s linear infinite;
    }
}