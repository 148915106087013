@import '../node_modules/bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Avenir-Black';
  src: url('/fonts/Avenir/Avenir-Black.ttf'), url('/fonts/Avenir/Avenir-Black.woff'), url('/fonts/Avenir/Avenir-Black.eot');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Light';
  src: url('/fonts/Avenir/Avenir-Light.ttf'), url('/fonts/Avenir/Avenir-Light.eot'), url('/fonts/Avenir/Avenir-Light.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('/fonts/Avenir/Avenir-Medium.ttf'), url('/fonts/Avenir/Avenir-Medium.woff'), url('/fonts/Avenir/Avenir-Medium.eot');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/fonts/Lato/LatoLight.ttf'), url('/fonts/Lato/Lato-Light.eot'), url('/fonts/Lato/Lato-Light.woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir-Medium';
  font-size: 15px;
  color: #333;
  line-height: 1.6;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  &:hover {
    cursor: pointer;
  }
}

input[type=text],
input[type=tel],
input[type=email],
input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;


  &_center {
    align-items: center;
    justify-content: center;
  }

  &_start {
    justify-content: flex-start;
    align-items: flex-start;
  }

  &_end {
    justify-content: flex-end;
    align-items: flex-end;
  }

  @media(max-width: 1024px) {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    overflow-x: hidden
  }

  @media(max-width: 767px) {
    max-width: 760px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden
  }

  @media(max-width: 374px) {
    max-width: 370px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden
  }
}

.alert {
  position: fixed !important;
  top: 8% !important;
  z-index: 100 !important;
}

//Popup animations

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


